/**
* Report Check - Layout and consolidated 'components'
*/
#REPORT_TYPE {
  @include header-underline(none);
  font-size: 3rem;
  line-height: 3rem;
  padding-top: 0;
  padding-bottom: 0;
}
#DIGITAL_IMAGE {
  img {
    width: 85%;
  }
  @include media-breakpoint-up(md) {
    margin: 0 18px;
    img {
      width: 100%;
    }
  }
}

hr.dotted-separator {
  flex: 1 1 0%;
  border-bottom: 1pt dotted $black;
}

#right-rail {
  @include media-breakpoint-up(lg) {
    // Only appply the dotted left border to the rightRail when it no longer has the 'skeleton' loader ie. when the
    // ajax request for the right rail content from the CMS has completed and been injected into the DOM
    &:not(:has(> .skeleton)) {
      background: url(https://www.gia.edu/img/icons/bg-dots-double-vertical.png) repeat-y;
    }
  }
  & > *:not(:first-child) {
    background: transparent url(https://www.gia.edu/img/global/bg-dots-double-horizontal.png) no-repeat scroll center
      top;
  }
  &.card {
    border: 0;
  }
  .card {
    .card-body {
      padding: 0;
    }
  }
  div[class^='grading-scale'] {
    img {
      width: 75%;
      max-width: 375px;
      @include media-breakpoint-up(md) {
        width: 100%;
        padding: 0 1rem;
      }
    }
  }
  .caption {
    font-size: 12px;
    font-size: 0.75rem;
    text-align: center;
    line-height: 18px;
    margin-top: 10px;
  }
  hr {
    display: none;
  }
}

/**
* Report Check - Disparate CSS for specific reports, tab layout, and other components
*/
.reporttype #title {
  text-align: left;
  background: none;
  font-size: 3rem;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 10%;
}

#REPORT_TYPE_SUBTITLE {
  color: $black;
  font-family: $freight-sans-pro;
  font-size: $font-size-sm;
}
.title {
  background: none;
  text-align: left;
  margin-bottom: 5px;
  font-family: $freight-sans-pro;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 15px 0;
  line-height: 36px;
  color: #202020;
  text-transform: uppercase;
}
.proportion img {
  width: 100%;
  height: initial !important;
}
#ex5.proportion img {
  max-width: 275px;
}

.dor-polished img,
.dor-rough img {
  width: 250px;
  max-width: calc(50% - 32px);
}

#ex3,
#ex4 {
  position: relative;
}
#ex3 {
  margin: 0 25px;
}
#ex3 .report-magnify,
#ex4 .report-magnify {
  right: 0.4rem;
}

.search-another-report {
  max-width: 150px;
  display: inline;
}
#submit-another-report {
  font-family: freight-sans-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: none;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 18px;
  padding: 10px 13px;
  border-radius: 4px;
  margin: 0;
  display: inline-block;
  background-color: #f1f1f1;
  border: 0;
  color: #202020;
  cursor: pointer;
}
/* ------------------------------------------------------------------------------------*/

/**
* Miscellaneous
*/
.main-loader {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.main-container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  padding: 1rem;
  gap: 2rem;
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.details-skeleton {
  flex: 3;
  min-height: 100vh;
}

.rr-skeleton {
  flex: 1;
  height: 75vh;
}

[data-fancybox] {
  cursor: pointer;
  position: relative;
}

[data-fancybox]:hover,
[data-fancybox]:focus {
  border-color: transparent;
}

/* Fancybox magnifying glass */
[data-fancybox]::after {
  content: '';
  background: url(https://www.gia.edu/img/sprites-s6da4de9852.png) 0 -829px no-repeat;
  display: block;
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  transform: translate(50%, -50%);
}

@media all and (max-width: 768px) {
  .details-skeleton {
    min-width: 100%;
  }

  .rr-skeleton {
    min-width: 100%;
    height: 25vh;
  }
}

#tracr-accordion.accordion img {
  max-width: 175px;
  opacity: 60%;
}
