.single-tooltip-label {
  cursor: pointer;
  border-bottom: 1px dotted text-colors('light-grey');
  padding-bottom: 2pt;
}

.table-image {
  width: calc(100% - $grid-gutter-width);
}

.table-image#CIIMG {
  width: min(100%, 265px);
}
