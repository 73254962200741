.card--dynamic {
  .btn {
    padding: 6px 12px;
    place-items: center;

    opacity: 1;
    transition: opacity 0.3s ease;
    @media (hover: hover) and (pointer: fine) {
      opacity: 0;
    }

    &[data-icon]:after {
      content: '';
      position: static;
      display: inline-flex;
      background-repeat: no-repeat;
      width: 13px;
      height: 12px;
      margin-left: 0.5rem;
    }

    &[data-icon='arrow-right']:after {
      background-image: url('//gia.edu/assets/img/icons/ic_round-arrow-back.svg');
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 1;

      &[data-icon]:after {
        // filter to convert black icon to white
        filter: invert(95%) sepia(0%) saturate(7481%) hue-rotate(77deg) brightness(106%) contrast(100%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:has(.btn:focus),
  &:has(.btn:hover) {
    .card-img-overlay {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  .card-img-overlay {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}
