// ----- GIA Specific Color Mixins -----

/* 
Example Usgae:  
p {
    color: pastel-colors("gold"); 
    background-color: bg-colors("light-grey");
} 
*/

// Mixin for GIA Pastel color palette
@function pastel-colors($key) {
  @if map-has-key($pastel-colors, $key) {
    @return map-get($pastel-colors, $key);
  }
  @warn "Unknown `#{$key}` in $pastel-colors.";
  @return null;
}

// Mixin for GIA background color palette
@function bg-colors($key) {
  @if map-has-key($bg-colors, $key) {
    @return map-get($bg-colors, $key);
  }
  @warn "Unknown `#{$key}` in $bg-colors.";
  @return null;
}

// Mixin for GIA text color palette
@function text-colors($key) {
  @if map-has-key($text-colors, $key) {
    @return map-get($text-colors, $key);
  }
  @warn "Unknown `#{$key}` in $text-colors.";
  @return null;
}
