.xtable-info {
  top: 1.5rem;
  right: 0;
  margin: 0.5rem 1rem;
  width: 14px;
  height: 16px;
  background: transparent url('//gia.edu/img/icons/info2x.png') no-repeat center / contain;
  border: none;
  transform: translateY(-100%);
  z-index: 1020;
  @media all and (min-width: 768px) {
    margin: 0.5rem 2.5rem;
  }
}

.xtable {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  margin-bottom: 2rem;
  &.xtable-gemstone {
    margin-bottom: 1rem;
  }
}

.xtable-head {
  font-weight: 700;
}

.xtable-body {
  color: $black;
  font-family: $font-family-base;
  border: 2px solid rgba(169, 169, 169, 0.5);
  border-radius: 0.5rem;
}
