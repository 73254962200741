#lgdr-banner {
  h1 {
    background-image: none;
    font-family: Arial, Helvetica, sans-serif;
    color: #1f2e7b;
    letter-spacing: normal;
    font-size: 0.8125rem;
    line-height: 1.5;
  }
  @media (min-width: 576px) {
    h1 {
      font-size: 1rem;
    }
  }
}

/**
 * Labortory Grown Diamond Report CTA
 */
#lgdr-cta {
  background: url('//gia.edu/img/lgdr-footer-banner.jpg') no-repeat 100%;
  margin-right: calc($grid-gutter-width / 2);
}
