/* || Report Tabs (Legacy) */
.nav-tabs {
  .nav-item {
    .nav-link {
      position: relative;
      text-transform: uppercase;
      background-color: bg-colors('light-grey');
      font-family: $freight-sans-pro;
      font-size: 0.8125rem;
      letter-spacing: 1px;
      font-weight: 700;
      padding: 10px 15px;
      margin: 2px;
      border: none !important;
      border-radius: 0.25rem;

      &:hover,
      &:focus {
        border-color: transparent;
      }

      &.active {
        color: $white;
        background-color: bg-colors('darker-grey');

        &:after {
          @include media-breakpoint-up(md) {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            display: block;
            height: 5px;
            width: 10px;
            background: url('https://www.gia.edu/img/icons/arrow-down.png') no-repeat;
            transform: translateX(-50%);
          }
        }
      }

      @include media-breakpoint-down(md) {
        padding: 8px 12px;
        font-size: 12px;
      }
    }
  }
}

/* || Report Nav (Jewelry Grading Report) */
.report-nav {
  flex-wrap: nowrap !important;

  .nav-item {
    flex: 1 1 auto;

    .nav-link {
      width: 100%;
      color: text-colors('grey');
      font-family: $freight-sans-pro;
      font-size: $h5-font-size;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-base;
      }

      font-weight: $display4-weight;
      text-transform: uppercase;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-color: transparent;
      background-color: bg-colors('grey') !important;
      background-color: $white;
      letter-spacing: 1px;

      @include media-breakpoint-down(sm) {
        text-decoration: none;
      }

      &.active {
        color: $white;
        background-color: $black !important;
        border-color: $black;
        font-weight: $display5-weight;
        text-decoration: none !important;
        letter-spacing: normal;

        &:focus {
          border-color: $black !important;
        }

        &:after {
          // Override the default nav-tab down arrow
          background-image: none;
        }
      }

      &:first-of-type {
        border-radius: 0.5rem 0 0 0.5rem;

        &.active {
          border-radius: 0.5rem;
        }
      }

      &:last-of-type {
        border-radius: 0 0.5rem 0.5rem 0;

        &.active {
          border-radius: 0.5rem;
        }
      }
    }
  }
}
