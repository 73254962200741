section,
div,
tr {
  &.grey {
    background-color: bg-colors('grey');
  }
  &.black {
    background-color: $black;
  }
}
