//Response css, base styles are set in the vendor dl-menu scss
.mobile-menu {
  display: block;

  @media all and (min-width: 1149px) {
    display: none;
    /* .top-bar toggled to d-block here */
  }

  border-bottom: 1px solid bg-colors('grey');
  position: relative;

  #mobile-menu-btn {
    background-color: transparent;
  }

  a {
    @include fontSize(12px);

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  .dl-back {
    padding: 0 !important;
  }

  .dl-back:after {
    content: none !important;
  }

  /* ------------------------------------------------------------------------------ */
  /*
    override the pre-existing pseudo-element :after 'left-gold-arrow.svg' as we replace 
    arrow pseudo-element with arrow background image on the button inside the li.dl-back
    */
  /* ------------------------------------------------------------------------------ */
  .dl-slider a[target='_blank']::after {
    content: url('https://www.gia.edu/img/global-header/desktop/external-link-icon.svg');
    margin-left: 0.75em;
  }

  .dl-slider.gemkids-menu-link-mobile a[target='_blank']::after {
    content: '';
  }

  .dl-slider a,
  .dl-slider {
    font-family: $freight-sans-pro;
    font-weight: bold !important;
    @include fontSize(18px);
    text-transform: uppercase;
    width: 100%;
  }

  .dl-slider button,
  .dl-back button {
    display: flex;
    align-items: center;
    border: none;
    color: $white;
    display: flex;
    font-family: $freight-sans-pro;
    font-size: 1.125rem;
    font-weight: 700;
    height: 3.6rem;
    line-height: 1.5em;
    position: static;
    padding: 20px 15px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
  }

  .dl-slider button {
    justify-content: space-between;
  }

  .dl-back button {
    justify-content: flex-start;
  }

  .dl-slider button[aria-expanded]:after {
    content: url('https://www.gia.edu/img/global-header/mobile/right-gold-arrow.svg');
  }

  .dl-back button:before {
    content: url('https://www.gia.edu/img/global-header/mobile/left-gold-arrow.svg');
    margin-right: 0.75rem;
  }

  li,
  .lang-area {
    border-bottom: 1px solid text-colors('grey');
  }

  .dl-submenu-child {
    border-bottom: 0px;
  }

  li,
  .row {
    color: white;

    a {
      color: white;
      display: inline-block;
      padding: 20px 15px;
      @include fontSize(12px);

      &:active {
        border: 0px;
      }
    }

    .col-6 {
      padding: 10px 0;
    }
  }

  .dl-back {
    padding: 15px 30px;
    font-family: $freight-sans-pro;
    font-weight: 700;
    @include fontSize(18px);
  }

  .row {
    a {
      padding: 5px 20px;
      display: inherit;
      font-family: $font-family-sans-serif;
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .dl-menu.dl-menuopen {
    height: stretch;
    min-height: fit-content;

    @media (min-width: 576px) {
      height: fit-content;
    }
  }

  //langguage selector button
  .lang-select {
    background: text-colors('medium-grey');
    padding: 10px 25px 7px !important;
    margin: 20px 0 10px;
    border-radius: 4pt;
    z-index: 200;
    position: relative;
    font-family: $font-family-sans-serif;
    text-transform: capitalize;

    .lang-disc {
      font-weight: normal;
    }

    .lang-display {
      font-weight: bold;
    }

    img {
      padding: 0 5px 0;
    }
  }

  .dl-subview .row {
    display: none;
  }

  small {
    top: 10px;
    left: 15px;
    position: relative;
    color: text-colors('light-grey');
    @include fontSize(12px);
  }

  .phone {
    text-decoration: underline;
    margin-left: -3px;
  }
}
