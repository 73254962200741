html,
body {
  overflow-x: hidden;
}

.container-default {
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  max-width: 1008px;
  padding-left: 18px;
  padding-right: 18px;
  position: relative;
  width: 100%;
  // z-index: 200;
}

/* *************************************************************** */
/* Global styling for Report Check V3 goes here! */
html {
  font-size: 16px;
}

body {
  margin: 0;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #f1f1f1;
  border-radius: 0.25rem;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.header-skeleton {
  width: 100%;
}

.top-bar-skeleton,
.nav-skeleton {
  max-width: 1008px;
  width: 100%;
  margin: 0 auto;
}

.top-bar-skeleton {
  /* height: 4.66rem; */
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.logo-skel {
  height: 55px;
  width: 164px;
}

.top-misc {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.top-misc > * {
  height: 32px;
  flex: 1;
  margin-left: 1rem;
}

.search-skel {
  max-width: 40px;
}

.nav-skeleton {
  /* height: 3.33rem; */
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 3rem; */
}

.nav-skeleton .skeleton {
  height: 2rem;
  margin-right: 1.25rem;
}

.nav-skeleton .skeleton:last-child {
  margin-right: 0;
}

.table-striped tbody tr.makeItWhite {
  background-color: transparent !important;
  background-color: #fff !important;
}

/*
    Global header is 9rem tall without notification.
    11rem with notification.
    dropdown bar - 3.5rem
    top bar - 5rem
    divider - 0.5rem margin
*/
/* .global-header {
    min-height: 9rem;
    max-height: 11rem;
    overflow: hidden;
}

.global-header.ready {
    overflow: initial;
    max-height: initial;
}

@media screen and (max-width: 1149px) {
    .global-header.ready {
        min-height: unset;
    }
} */

.global-header .top-bar {
  min-height: 5rem;
  max-height: 5rem;
}

.global-header .dropdown-bar {
  min-height: 3.5rem;
}

/* *** Global Banner CSS */
#global-banner {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #fffac2;
  width: 100%;
  top: 0;
  /* Below 'height' set dynamically based on number of notification messages */
  /* height: calc(35px * 3); */
  transition: all 300ms ease;
}

#global-banner[aria-hidden='true'] {
  height: 0 !important;
  overflow: hidden;
}

#global-banner[aria-hidden='true'] .messages,
#global-banner[aria-hidden='true'] #global-banner-close {
  opacity: 0;
  transition: opacity 150ms ease;
}

#global-banner .messages {
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 960px !important;
  margin: 0 auto;
}

#global-banner .message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 35px;
  border-bottom: 1px solid #eee;
}

#global-banner .message a {
  font-size: 0.835rem;
  color: #0477aa;
  letter-spacing: 0;
  text-transform: none;
  font-weight: 700;
  text-overflow: ellipsis;
  font-family: Arial, sans-serif;
}

#global-banner .message a:hover {
  border-bottom-color: transparent;
}

#global-banner .message:last-of-type {
  border-bottom: none;
}

#global-banner #global-banner-close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  background: url('https://www.gia.edu/img/icons/grey-close-x.svg') no-repeat;
  border: none;
}

#main.ready {
  min-height: unset;
}

.related-container {
  min-height: 25vh;
}

.footer-container {
  background-color: #2c2c2c;
  margin: 0;
  min-height: 34rem;
}

/* TODO: Remove/revise below once the 'Back to Top' is implemented for RCV3 */
@media all and (max-width: 576px) {
  footer .footer-content-top .brand img {
    margin: 1.75rem 0 !important;
  }
}
