.table {
  text-align: left;
  font-size: 0.75rem;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: calc(100% - 20px);
  }
  tbody {
    th,
    td {
      padding: 0.5rem 0.5rem 0.5rem 1.75rem;
      border: none;
    }
    td:has(.table-image) {
      padding: 0.5rem 0;
    }
    td {
      color: $black;
      line-height: 1.85;
      &:only-child {
        width: 100%;
      }
      &:first-child:not(:only-child) {
        width: 35%;
      }
      &:last-child:not(:only-child) {
        font-weight: 700;
      }
    }
  }
}
