.xtable-header {
  font-family: 'freight-sans-pro', sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #000;
  margin: 0 1rem;
  padding-bottom: 0.5rem;
  @media all and (min-width: 768px) {
    margin: 0 2.5rem;
  }
}
