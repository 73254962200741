// ---- Adding 1px dotted border ----
// Takes top or bottom as pos(ition) parameter
// Default padding set to 40px

/* 
Example Usgae:  
.element {
    @include dotted-border("bottom", 25px, 25px);
}
*/
@mixin dotted-border($pos, $padding-top: 40px, $padding-bottom: 40px) {
  width: 100%;
  padding-top: $padding-top;
  padding-bottom: $padding-bottom;

  @if $pos== 'top' {
    border-top: dotted 1px bg-colors('medium-grey');
  } @else if $pos== 'bottom' {
    border-bottom: dotted 1px bg-colors('medium-grey');
  }
}
