#main-container {
  min-height: 100vh;
  &.ready {
    min-height: unset;
    .main-skeleton.cloak {
      opacity: 0;
      z-index: -1;
      display: none;
    }
  }
  .main-skeleton {
    height: 100vh;
    min-height: 100vh;
    padding: 0rem;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    position: absolute;
    z-index: 1030;
    background-color: #fff;
    &.cloak {
      position: absolute;
      z-index: 1030;
      background-color: #fff;
      animation: none;
      opacity: 1;
      pointer-events: none;
    }
  }
  .skeleton {
    background-color: #f1f1f1;
  }

  .hero-skel {
    max-width: 1008px;
    min-height: 267px;
    margin: 1rem auto;
    margin-bottom: 2rem;
    text-align: center;
  }

  .preheader-skel {
    min-height: 1.25rem;
    max-width: 10rem;
  }

  .headline-skel {
    min-height: 4rem;
    max-width: 20rem;
    margin-top: 0.75rem;
    margin-bottom: 2.5rem;
  }

  .lead-skel {
    min-height: 4.5rem;
    max-width: 90%;
    max-width: 972px;
  }

  .content-skel {
    min-height: 70vh;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    background-color: #f1f1f1;
  }

  .content-search-skel {
    background-color: #fff;
    width: 100%;
    max-width: 972px;
    height: 6.5rem;
    border: 5px solid #ccc;
    border-radius: 0;
    margin: 3.75rem auto;
  }
}

.intro .preheader,
.intro h1,
.intro .lead,
#main-container .hero-skel .skeleton {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.report-check-landing {
  background-color: bg-colors('light-grey');
  padding: 4rem 0;
  margin: 2rem 0;

  table {
    font-size: $small-font-size;
    color: #666;
    margin-bottom: 1rem;
    border: 0;
    width: 100%;
    @include fontSize(12px);

    tr {
      th,
      td {
        &:first-of-type {
          text-align: left;
        }
      }
    }

    tr,
    td,
    th {
      border: none;
    }

    th {
      color: text-colors('darker-grey');
    }

    th,
    td {
      border: none;
    }

    img {
      margin: 0;
    }

    .tooltipIcon {
      // overrides style tag in the report check landing WYSIWYG content
      background-image: url(//gia.edu/assets/img/icons/info-icon.svg);
    }
  }
}

// TODO: Rename this as it's very confusing what the purpose/meaning of this class is
.report-check {
  @include make-container(1008px, transparent);
  text-align: center;
  z-index: 200;
  position: relative;

  h3 {
    @include header-underline(center);
    font-family: $callout-header-font;
    font-weight: $display5-weight;
    font-size: $h6-font-size;
    letter-spacing: 1px;
  }

  .global-link {
    padding: 0 0 0 15px;
    margin-left: 10px !important;
    top: 2px;
    position: relative;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .form-row {
    background: $white;
  }

  .search-btn {
    color: $white;
    background-color: $black;
  }
}
