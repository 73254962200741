.related-content-cards {
  @include make-container(1008px);
  padding-bottom: 50px;

  h3 {
    @include header-underline(center);
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
