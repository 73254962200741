.btn {
  //shared CTA button styles
  @include fontSize(13px);
  padding: 10px 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: $btn-header-font;
  text-decoration: dotted;
  border: 0;
  outline: none;

  &:focus {
    outline: 0 !important;
  }

  &.btn-primary {
    background-color: $red;
    // background-color: bg-colors("darker-grey");
    color: $white;
    text-transform: uppercase !important;

    &:hover,
    &:focus,
    &:active {
      background-color: $red !important;
      // background-color: bg-colors("darker-grey") !important;
      color: $white !important;
      border: 0 !important;
    }
  }

  //grey CTA button
  &.btn-secondary {
    background-color: #e8e8e8;
    color: $black;
    border: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: #e8e8e8 !important;
      color: $black !important;
      border: 0 !important;
    }
  }

  &.btn-dark {
    color: $white;
    background-color: bg-colors('darker-grey');
    border: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: bg-colors('darker-grey');
      border: 0;
    }
  }

  &.btn-light {
    background-color: $white;
    color: text-colors('dark-grey');
    border: 3px solid bg-colors('dark-grey');
  }

  &.btn-light--static,
  &.btn-light--static:focus,
  &.btn-light--static:hover {
    background-color: $white !important;
    color: text-colors('dark-grey') !important;
    border: 3px solid bg-colors('dark-grey') !important;
  }

  &.btn-light--dynamic:focus,
  &.btn-light--dynamic:hover,
  &.btn-light--dynamic:active {
    background-color: bg-colors('dark-grey') !important;
    color: $white !important;
    border: 3px solid bg-colors('dark-grey') !important;
  }
}

button,
.btn,
div,
a {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

button:hover {
  cursor: pointer;
}

button:active {
  outline: none;
}

.button-action {
  @extend .btn;
  @extend .btn-primary;
}
