/* || General settings */
@import 'mixins/colors';
@import 'mixins/rem';
@import 'settings';

/* ||  Vendors */
@import 'vendors/dl-menu';

/* || Bootstrap */
@import 'bootstrap';

/* || Fancybox */
@import '@fancyapps/ui/dist/fancybox/fancybox';

/* || Custom mixins  */
@import 'mixins/border';
@import 'mixins/container';
@import 'mixins/header';

/* || Base typograhphy  */
@import 'global';
@import 'typography';

/* || Form specific */
@import 'forms/forms';
@import 'forms/buttons';

/* || Shared feature/(s) */
@import 'shared/buttons';
@import 'shared/callout';
@import 'shared/card';
@import 'shared/footer';
@import 'shared/hr';
@import 'shared/header';
@import 'shared/intro';
@import 'shared/list-group';
@import 'shared/mobile-menu';
@import 'shared/section';
@import 'shared/fancybox';
@import 'shared/report-nav';

/* || Miscellanious */
@import 'misc';

/* || React Components */
@import '@@components/AGSReport/';
@import '@@components/Card/';
@import '@@components/FeaturedImage/';
@import '@@components/Footer/';
@import '@@components/FullScreenButton/';
@import '@@components/GridTable/';
@import '@@components/GridTableHeader/';
@import '@@components/GridTableRow/';
@import '@@components/Header/';
@import '@@components/InscriptionRow/';
@import '@@components/InscriptionImage/';
@import '@@components/LabGrownBanner/';
@import '@@components/Landing/';
@import '@@components/OriginImageRows/';
@import '@@components/Related/';
@import '@@components/ReportBasicInfo/';
@import '@@components/ReportSupplemental/';
@import '@@components/ReportSupplementals/';
@import '@@components/Results/';
@import '@@components/SimpleSlider/';
@import '@@components/SingleTooltip/';
@import '@@components/SupplementalsAccordion/';
@import '@@components/Table/';
@import '@@components/TableHeader/';
@import '@@components/TableRow/';
@import '@@components/Tooltip/';
