//  Table of Contents:
//
//   1. Global colors and fontss
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ...

// Monotype Fonts
$din-next-regular: 'DINNextRegular', sans-serif;
$din-variable: 'Variable', sans-serif;

// Typekit Fonts
$din-web-condensed: 'din-condensed-web', sans-serif;
$freight-sans-pro: 'freight-sans-pro', sans-serif;

// ---- GIA Custom color Palettes ----
$white: #fff;
$black: #000;
$grey: #666;
$main-copy-color: $grey;
$red: #de111e;
$green: #36a400;

$bg-colors: ();
$bg-colors: map-merge(
  (
    'light-grey': #f1f1f1,
    'grey': #eeeeee,
    'medium-grey': #cccccc,
    'dark-gray': #999999,
    'darker-meduim-grey': #9b9b9a,
    'yellow': #fffac2,
    'navy': #121c28,
    'gold': #a7947a,
    'secondary-gold': #a7947a,
    'dark-grey': #2c2c2c,
    'darker-grey': #202020,
  ),
  $bg-colors
);

$text-colors: ();
$text-colors: map-merge(
  (
    'lighter-grey': #f1f1f1,
    'light-grey': #999999,
    'grey': #666666,
    'medium-grey': #535353,
    'dark-grey': #2c2c2c,
    'darker-grey': #202020,
    'gold': #a7947a,
    'blue': #0477aa,
    'red': #de111e,
  ),
  $text-colors
);

$pastel-colors: ();
$pastel-colors: map-merge(
  (
    'blue': #c4cfd3,
    'green': #b1cdb7,
    'pink': #d1b1c4,
    'gold': #cbc0a0,
  ),
  $pastel-colors
);

// ---- Custom Grid breakpoints -----
$grid-columns: 12;
$grid-gutter-width: 36px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1008px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1008px,
);

$body-bg: $white;
$body-color: $main-copy-color;

// ------ Base GIA Typography settings ----

// Font styles
$font-family-sans-serif: Arial, Helvetica, sans-serif;
$font-family-monospace: Arial, Helvetica, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);

$h1-font-size: $font-size-base * 3.75;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2.18;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$h1-article-font-size: $font-size-base * 3.15;
$h2-article-font-size: $font-size-base * 3.5;
$h3-article-font-size: $font-size-base * 2;
$h4-article-font-size: $font-size-base * 1.5;

$headings-margin-bottom: 15px;
$headings-font-family: $din-web-condensed;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: text-colors('darker-grey');

$headings-article-font-family: $freight-sans-pro;
$headings-article-font-weight: 300;

$callout-header-font: $freight-sans-pro;
$list-header-font: $freight-sans-pro;
$btn-header-font: $freight-sans-pro;

$lead-font-size: ($font-size-base * 1.25);
$lead-font-weight: 300;

$small-font-size: 80%;

// Cards
$card-border-radius: 0;
$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;
$card-border-radius: $card-border-radius;
$card-bg: $white;

// Links
$link-color: text-colors('red');
$link-decoration: none;
$link-hover-color: text-colors('red');
$link-hover-decoration: 1px dotted text-colors('red');

$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 36px;

$display1-weight: 300;
$display2-weight: 400;
$display3-weight: 400;
$display4-weight: 400;
$display4-weight: 400;
$display5-weight: 700;
$display-line-height: 1rem;
