// ----- Semantic Grid Mixin that takes width as a parameter -----
// Sets width on container element instead of using .container class. Can also set backgrounf color of element

/* 
Example Usgae:  
.image-text-callout {
    @include make-container(1008px,bg-colors("light-grey"));
}
*/

@mixin make-container($max-width: 100%, $bg-color: $white) {
  width: 100%;
  max-width: $max-width;
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
  background-color: $bg-color;
}
