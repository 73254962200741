// ---- Mixin to calculate REM values, takes px value as parameter

/* 
Example Usgae:  
p {
    @include fontSize(12px);
}
*/

@function calculateRem($size) {
  $remSize: calc($size / 16px); //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}

// Mixin that will include the fall back px declaration as well as the calculated rem value.
@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}
