.accordion {
  background: bg-colors('grey');
  margin-bottom: 0.5rem;

  .accordion-item {
    .accordion-header {
      button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 2rem 2rem 2rem 3rem;
        font-family: $freight-sans-pro;
        @include fontSize(14px);
        font-weight: 700;
        letter-spacing: 1px;
        text-align: left;
        text-transform: uppercase;
        color: text-colors('dark-grey');
        background: transparent;
        border: none;

        &[aria-expanded='false'] > span:nth-child(2):after {
          content: 'Show Details';
        }

        &[aria-expanded='true'] > span:nth-child(2):after {
          content: 'Hide Details';
        }

        span:nth-child(2) {
          color: text-colors('blue');
        }

        span:nth-child(2):before {
          background-image: url('https://www.gia.edu/img/campus/bg-toggle.png');
          background-size: cover;
          content: '';
          display: inline-block;
          height: 10px;
          margin-right: 7.5px;
          width: 15px;
        }

        &[aria-expanded='false'] > span:nth-child(2):before {
          background-position: 0 100% !important;
        }
      }
    }

    .accordion-body {
      h3,
      h4 {
        @include fontSize(14px);
        @include header-underline(left);
        font-family: $freight-sans-pro;
        font-weight: 700;
        letter-spacing: normal;
        padding: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
