// ----- Mixin to add 'hr' under headers -----
// There are two types of headings with the hr - left aligned and center aligned
// Use center to center the header and underline, use left to left align
/* 
Example Usgae:  
 h1 {
        @include header-underline(center);
    }

Output:
.simple-introduction h1 {
    ---> text-align: center;
    ---> background-position: center bottom;
    background-image: url(../img/icons/heading-underline.png);
    background-repeat: no-repeat;
    padding: 15px 0;
}
*/

@mixin header-underline($text-align) {
  @if $text-align== 'center' {
    text-align: center;
    background-position: center bottom;
  } @else if $text-align == 'left' {
    text-align: left;
    background-position: left bottom;
  } @else if $text-align == 'right' {
    text-align: right;
    background-position: right bottom;
  } @else if $text-align == 'none' {
    background: none;
  }
}
