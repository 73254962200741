/**
* AGS IDEAL Report
* JIRA: [RC-159], [GIA-8667], [GIA-8666], [GIA-8724]
*/

#ags-report {
  margin: 1rem 0 3.75rem 0;
  /* || AGS Color Wheel */
  --ags-blue: #213362;
  --ags-black: #000000;
  --ags-dark-gray: #666666;
  --ags-light-gray: #f1f1f1;
  // --ags-gray: #6D6D6D;

  /* || AGS Typography */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $din-next-regular;
    background-image: none !important;
    color: var(--ags-blue);
    padding: 18px 0;
    margin: unset;
  }

  p {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }

  .text-muted {
    color: var(--ags-dark-gray) !important;
  }

  /* || AGS Misc */
  #ags-header {
    font-weight: 700;
    font-size: 32px !important;
    line-height: 32px !important;
    letter-spacing: 1px;
  }

  #ags-header sup {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 40%;
    vertical-align: super;
  }

  #ags-header span {
    font-weight: normal;
  }

  #ags-sub-header {
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-bottom: 1rem;
    @media screen and (min-width: 576px) {
      margin-bottom: 1.125rem;
    }
  }

  #ags-download-pdf {
    display: block;
    margin: 0.875rem 0;
    @media screen and (min-width: 576px) {
      margin: 1.125rem 0;
    }
    &:before {
      content: '';
      display: inline-block;
      background: url(https://www.gia.edu/assets/img/icons/red-down-arrow.svg) no-repeat;
      background-size: contain;
      background-position: center;
      margin-right: 4px;
      width: 10px;
      height: 10px;
      border-bottom: 1.5px solid $red;
    }
  }

  .ags-report-title-bar {
    display: flex;
    align-items: center;
    background-color: var(--ags-blue);
    width: 100%;
    height: 40px;
    margin: 0.75rem 0 1rem 0;
    .box-gray {
      background-color: var(--ags-dark-gray);
      height: 100%;
      width: 14px;
    }
    .title {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 16px;
      font-style: italic;
      font-weight: normal;
      color: var(--white);
      padding: 0 14px;
      text-transform: none;
    }
  }

  .report-data-subhead {
    color: var(--ags-black);
    text-align: left;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-bottom: 0.25rem;
  }

  /* || AGS Figures */
  figure {
    border: none;
    margin: 1rem 0;
    padding: unset;
    img {
      width: 100%;
      margin-bottom: 14px;
    }
    figcaption {
      color: var(--ags-black);
      text-align: left;
      width: 100%;
      font-size: 0.75rem;
      line-height: 0.875rem;
    }
  }

  figure#aset-map {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 1rem 0 2rem 0;
    img:nth-of-type(1) {
      width: 62.5%;
      max-width: 250px;
    }
    img:nth-of-type(2) {
      width: 37.5%;
      max-width: 150px;
    }
    figcaption {
      margin-top: 0.75rem;
    }
  }

  /* || AGS Report Data */
  dl.report-data {
    margin: 1rem 0;
    .report-data-row {
      display: flex;
      align-items: baseline;
      height: 32px;
    }
    dt,
    dd {
      font-size: 0.75rem;
      line-height: 1rem;
      color: #000;
      white-space: nowrap;
    }
    dt {
      cursor: pointer;
      margin-right: 3px;
      font-weight: 500;
      border-bottom: 1pt dotted var(--ags-blue);
      .tooltip {
        display: none;
        bottom: 0.5rem;
        left: -1.125rem;
      }
    }
    dd {
      margin-left: 3px;
      font-weight: 700;
    }
    .single-tooltip {
      left: 0;
      bottom: 0.375rem;
      transform: translateX(-5%);
    }
  }

  /* || AGS Light Performance Legend */
  .light-performance-legend {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    @media screen and (min-width: 590px) {
      flex-direction: row;
    }
    .card {
      flex: 1;
      border: none;
      margin-left: 1rem;
      @media screen and (min-width: 590px) {
        margin-left: 0;
        &:nth-child(even) {
          border-left: 1px solid rgb(190, 190, 190);
          border-right: 1px solid rgb(190, 190, 190);
          border-top: none;
          border-bottom: none;
        }
      }
      .card-body {
        padding: 0 1.125rem;
        .card-title,
        .card-subtitle {
          font-family: $din-next-regular;
          text-align: left;
          text-transform: none;
        }
        .card-subtitle {
          font-size: 1rem;
          font-weight: 300;
          line-height: 1.25rem;
        }
        .card-title {
          display: flex;
          align-items: center;
          font-size: 1.125rem;
          font-weight: 400;
          color: #000;
          line-height: 1.125rem;
          letter-spacing: 0.75px;
          margin: 0.25rem 0;
          &#brightness:before {
            content: '';
            display: inline-flex;
            background: url(https://www.gia.edu/assets/img/icons/brightness-50x50.svg) no-repeat;
            background-size: cover;
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 7.5px;
          }
          &#fire:before {
            content: '';
            display: inline-flex;
            background: url(https://www.gia.edu/assets/img/icons/fire-100x60.svg) no-repeat;
            background-size: cover;
            width: 1.666667rem;
            height: 1rem;
            margin-right: 7.5px;
          }
          &#contrast:before {
            content: '';
            display: inline-flex;
            background: url(https://www.gia.edu/assets/img/icons/contrast-50x50.svg) no-repeat;
            background-size: cover;
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 7.5px;
          }
        }
      }
    }
  }
  /* || AGS Disclaimer */
  .disclaimer-container {
    width: 100%;
    background-color: var(--ags-light-gray);
    padding: 24px 30px;
    .disclaimer-header {
      font-size: 10px;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    .disclaimer-copy {
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
}
// Temp measure to prevent 'FOUT' (Flash of Unstyled Text) on 'AGS IDEAL Report'
.related-content-cards {
  h3 {
    font-family: $din-next-regular;
  }
}
