.list-group {
  @include dotted-border('bottom', 30px, 0);

  @include media-breakpoint-down(sm) {
    padding-bottom: 30px;

    &.events-list,
    &.news-lists {
      padding-bottom: 15px;
    }
  }

  &:last-of-type {
    border: 0;
  }

  ul {
    margin-left: 15px;
    padding-left: 0;
  }

  li {
    @include fontSize(15px);
    line-height: 24px;
    list-style-type: none;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:before {
      content: '\2022';
      color: darken(pastel-colors('gold'), 20%); // darken($color, $amount) - mixin ships with sass
      margin-left: -15px;
      padding-right: 10px;
    }
  }

  a {
    margin-top: 20px;
    margin-bottom: 20px;

    &:hover {
      border-bottom: 1px dotted transparent;

      > span {
        border-bottom: 1px dotted $red;
      }
    }
  }

  // TODO: Why are these styles here and not in _footer.scss?
  .footer-content-top & {
    list-style-type: none;
    padding-top: 0;

    li {
      margin: 0;
      border-bottom: 1px dotted bg-colors('dark-gray');

      &:before {
        content: '';
        padding: 0;
        margin: 0;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    .list-group-item {
      @include fontSize(11px);
      background-color: transparent;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.5em;
      padding: 11px 0 7px;
      margin: 0;
      color: $white;
      border: none;
    }

    .list-group-item-action:focus,
    .list-group-item-action:hover {
      color: $white;
    }
  }
}
