.full-screen-btn {
  bottom: 0;
  right: 0;
  @media all and (min-width: 576px) {
    transform: translate(2rem, -100%);
  }
  &:after {
    content: '';
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-image: url(//gia.edu/img/icons/full-screen-arrows.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}
