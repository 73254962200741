.single-tooltip {
  cursor: pointer;
  position: absolute;
  left: 0.25rem;
  bottom: 0.75rem;
  font-size: 14px;
  font-family: $font-family-base;
  width: 260px;

  .single-tooltip-backdrop {
    content: '';
    display: block;
    background-image: url(//gia.edu/img/global/tooltip.png);
    background-repeat: no-repeat;
    &.single-tooltip-backdrop-top {
      background-position: center top;
      height: 12px;
    }
    &.single-tooltip-backdrop-bottom {
      background-position: center bottom;
    }
  }

  .single-tooltip-content {
    font-size: 0.75rem;
    color: text-colors('darker-grey');
    padding: 0 1.5rem 1.5rem;
    line-height: 1rem;
    text-transform: none;
    text-wrap: wrap;

    /**
     * TODO: For now some of the tooltip content coming via the JSON endpoint is incorrectly wrapped in a <p> tag
     * Once these content modules are "fixed" in Oracle WebCenter Sites we can then remove the below CSS
     */
    p {
      font-size: 0.75rem;
      margin-bottom: 0;
      line-height: 1rem;
    }
  }
}
