/* Common styles of menus */

.dl-menuwrapper {
  width: 100%;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
  position: absolute;
  z-index: 1021;
}

.dl-menuwrapper:first-child {
  margin-right: 100px;
}

.dl-menuwrapper button {
  cursor: pointer;
  position: absolute;
  top: -50px;
  right: 0;
  height: 35px;
  width: 70px;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

@media all and (max-width: 1148px) {
  .dl-menuwrapper button:hover,
  .dl-menuwrapper button.dl-active,
  .dl-menuwrapper ul {
    background-color: text-colors('dark-grey');
  }
}

.dl-menuwrapper .dl-trigger:before {
  content: url('https://www.gia.edu/img/global-header/mobile/menu-icon.svg');
}

.dl-menuwrapper .dl-active:before {
  content: url('https://www.gia.edu/img/global-header/mobile/menu-icon-open.svg');
}

.dl-menuwrapper ul {
  padding: 0;
  list-style: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.dl-menuwrapper li {
  position: relative;
}

.dl-menuwrapper li a {
  display: block;
  position: relative;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 100;
  color: $white;
  outline: none;
  border: 0px;
}

.no-touch .dl-menuwrapper li a:hover {
  background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back > a {
  padding-left: 33px;
  background: rgba(0, 0, 0, 0.1);
}

.dl-menuwrapper li.dl-back:after,
.dl-menuwrapper li > a:not(:only-child):after {
  position: absolute;
  content: url('https://www.gia.edu/img/global-header/mobile/right-gold-arrow.svg');
}

.dl-menuwrapper li.dl-back:after {
  left: 15px;
  top: 21px;
  height: 21px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
  right: 15px;
  top: 21px;
  color: rgba(0, 0, 0, 0.15);
}

.dl-menuwrapper .dl-menu {
  margin: 0;
  position: absolute;
  width: 100%;
  display: none;
  pointer-events: none;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: text-colors('dark-grey');
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
  transition: all 0s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
  display: block;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

/* Hide the inner submenus */

.dl-menuwrapper li .dl-submenu {
  display: none;
}

/* 
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link. 
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/

.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subviewopen > button,
.dl-menu.dl-subview li.dl-subview > a,
.dl-menu.dl-subview li.dl-subview > button {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
  font-family: $freight-sans-pro;
}

/* Dynamically added submenu outside of the menu context */

.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  margin: 0;
  height: 100%;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out-2 {
  -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
  animation: MenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 1;
  }
}

@keyframes MenuAnimOut2 {
  0% {
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 1;
  }
}

.dl-menu.dl-animate-in-2 {
  -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
  animation: MenuAnimIn2 0.3s ease-in-out;
}

@-webkit-keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
  -webkit-animation: SubMenuAnimIn2 0.4s ease-in-out;
  animation: SubMenuAnimIn2 0.4s ease-in-out;
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 0;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
  -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
  animation: SubMenuAnimOut2 0.3s ease-in-out;
}

@-webkit-keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
}

/* No JS Fallback */

.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
  display: block;
}

.no-js .dl-menuwrapper li.dl-back {
  display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
  background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
  content: '';
}
