.xtable-row {
  display: grid;
  padding: 0.75rem 0;
  gap: 0.5rem;
  &:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .xtable-cell {
    font-family: $din-variable;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.5;
    color: #202020;
    margin: 0 1rem;
    @media all and (min-width: 768px) {
      margin: 0 2.5rem;
    }
    a {
      color: #0477aa;
      font-size: 15px;
      line-height: 1.2;
      font-weight: 700;
      text-transform: none;
      &:hover,
      &:focus {
        border-bottom: none;
      }
      &[target='_blank']:after {
        content: '';
        display: inline-block;
        background-image: url('https://www.gia.edu/img/icons/external-link-icon-blue.svg');
        background-size: 100% 100%;
        height: 12px;
        width: 12px;
        margin: 0 0.25rem 0.25rem 0.25rem;
        vertical-align: middle;
      }
    }
    &:last-of-type:not(:only-child) {
      font-weight: 600;
    }
  }
}
