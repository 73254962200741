.form-row {
  background: bg-colors('light-grey');
  border-radius: 5px;
  label {
    color: text-colors('darker-grey');
    font-weight: bold;
    @include fontSize(15px);
    font-family: $freight-sans-pro;
  }

  input {
    border: 1px solid bg-colors('medium-grey');
  }
}

.form-control {
  border-radius: 0px;
  border: 1px solid bg-colors('grey');
  @include fontSize(13px);
}
