.report-basic-info {
  justify-content: space-between;
  h3 {
    background-image: none;
    color: #666;
    font-family: $freight-sans-pro;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: 1px;
  }

  p {
    color: #202020;
    font-size: 1.25rem;
    font-family: $din-variable;
  }

  @media screen and (min-width: 576px) {
    p {
      font-size: 1.5rem;
    }
  }

  > :first-child {
    padding-right: 1.5rem;
  }
  > :last-child {
    padding-left: 1.5rem;
  }
  @media all and (min-width: 1149px) {
    display: flex;
    justify-content: flex-start;
  }
}
