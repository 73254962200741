html {
  font-size: 1rem;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }

  @include media-breakpoint-only(md) {
    font-size: 1rem;
  }

  @include media-breakpoint-only(lg) {
    font-size: 1rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
  // letter-spacing: -3px;
  letter-spacing: -1px;
  text-transform: uppercase;

  &.article-header {
    font-family: $headings-article-font-family;
    text-transform: none;
    letter-spacing: normal;
    font-weight: $headings-article-font-weight;
    margin-bottom: 0;
  }
}

h1,
.h1 {
  font-size: $h1-font-size;
  line-height: 60px;

  .course-detail & {
    background-image: none;
  }

  &.article-header {
    font-size: $h1-article-font-size;
    line-height: 1;
    background-image: none;
  }
}

h2,
.h2 {
  font-size: $h2-font-size;

  @include media-breakpoint-down(sm) {
    @include fontSize(35px);
  }

  .about & {
    @include fontSize(40px);
    letter-spacing: -2px;
  }
}

h3,
.h3 {
  font-size: $h3-font-size;
  line-height: 36px;
  letter-spacing: -2px;
}

h4,
.h4 {
  font-size: $h4-font-size;
  letter-spacing: 0px;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}

h1,
h2,
h3,
h4 {
  // For alignment of underline, use header-underline mixin
  // See _introduction.scss for example
  background-image: url('//gia.edu/img/icons/heading-underline.png');
  background-repeat: no-repeat;
  padding: 15px 0;
}

ul {
  -webkit-padding-start: 0;
  padding-left: 0;

  .career & {
    list-style: none;
  }
}

p {
  @include fontSize(15px);
  line-height: 24px;

  @include media-breakpoint-down(sm) {
    @include fontSize(16px);
    a {
      @include fontSize(16px);
    }
  }

  a {
    @include fontSize(15px);
    font-family: $font-family-sans-serif;
    font-weight: $display2-weight;
    color: text-colors('blue');
    border-bottom: 1px dotted transparent;
    text-transform: none;
    letter-spacing: normal;
    &:hover {
      text-decoration: none;
      border-bottom: 1px dotted text-colors('blue');
      color: text-colors('blue');
    }
  }

  &.small {
    @include fontSize(12px);
    a {
      @include fontSize(12px);
    }
  }
}

.lead {
  line-height: 30px;
  font-family: $freight-sans-pro;

  .about & {
    text-align: center;
    margin: 8px 0 50px;
  }
}

a {
  @include fontSize(14px);
  text-transform: uppercase;
  font-weight: 700;
  font-family: $callout-header-font;
  border-bottom: 1px dotted transparent;
  letter-spacing: 1px;

  &.external-link-blue {
    color: text-colors('blue');
    font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
    text-transform: none !important;
    font-weight: 400;
  }

  &.external-link-blue:hover {
    border-bottom: 1px dotted text-colors('blue') !important;
    color: text-colors('blue') !important;
  }

  &.external-link-red {
    color: $red;
    font-family: 'freight-sans-pro', sans-serif;
    text-transform: uppercase !important;
    font-weight: 700;
  }

  &.external-link-red:hover {
    border-bottom: 1px dotted $red !important;
    color: $red !important;
  }

  &.external-link-blue[target='_blank']:after,
  &.external-link-red[target='_blank']:after {
    content: '';
    display: inline-block;
    background-size: 100% 100%;
    height: 12px;
    width: 12px;
    margin: 0 0.25rem 0.25rem 0.25rem;
    vertical-align: middle;
  }

  &.external-link-blue[target='_blank']:after {
    background-image: url('https://www.gia.edu/img/icons/external-link-icon-blue.svg');
  }

  &.external-link-red[target='_blank']:after {
    background-image: url('https://www.gia.edu/img/icons/external-link-icon-red.svg');
  }

  li & {
    margin-top: 0;
    color: text-colors('blue');
    text-transform: none;
    @include fontSize(13px);
    font-weight: normal;
    font-family: $font-family-sans-serif;
    letter-spacing: 0;
  }

  &:hover {
    border-bottom: 1px dotted $red;
  }
}

// Use when you want something to stand out
.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: $headings-font-family;
  color: text-colors('darker-grey');
  letter-spacing: -3px;
}

.sub-section-header {
  @include fontSize(15px);
  background-image: none;
  font-family: $list-header-font;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 30px;
}

.sub-text {
  @include fontSize(12px);
  line-height: 18px;
  padding: 0px;
  margin: 0px;
}

.small-header {
  @include fontSize(18px);
  @include header-underline(left);
  font-family: $list-header-font;
  font-weight: 700;
  letter-spacing: 1.5px;
  line-height: 18px;

  .career & {
    text-transform: uppercase;
  }

  .about & {
    @include fontSize(16px);
    background-image: none;
    text-align: center;
    padding-top: 0;
    letter-spacing: 1px;
  }
}

.smaller-header {
  @include fontSize(17px);
  font-weight: 700;
  font-family: $freight-sans-pro;
  line-height: 1em;
}

.post-header {
  font-weight: $display5-weight;
  text-transform: uppercase;
  display: block;
  color: text-colors('darker-grey');
}

.heading-centered {
  @include header-underline(center);
  letter-spacing: -2px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 0;
  margin-bottom: 0;
}

.preheader {
  display: block;
  color: text-colors('gold');
  font-size: $font-size-sm;
  font-family: $callout-header-font;
  letter-spacing: 1px;
  font-weight: $display5-weight;
  line-height: 1em;
  text-transform: uppercase;
}
