.fancybox .sprites-icon-media-gallery {
  background-position: 0 -829px;
  height: 20px;
  position: absolute;
  width: 20px;
  margin-top: -24px;
  right: 5px;
}

a.fancybox {
  position: relative;
}

@media (max-width: 576px) {
  .fancybox-show-thumbs .fancybox-caption-wrap.toggled .fancybox-caption {
    right: 110px !important;
  }
}

@media (max-width: 600px) {
  button.fancybox-button--fsenter,
  button.fancybox-button--play {
    display: none !important;
  }

  .fancybox-show-thumbs .fancybox-caption-wrap.toggled .fancybox-caption {
    right: 212px;
  }

  .fancybox-caption h4,
  .fancybox-caption p,
  .fancybox-caption {
    color: #fff !important;
  }

  .fancybox-thumbs__list {
    width: 100% !important;
    overflow: auto !important;
  }

  .fancybox-caption a {
    color: #0477aa !important;
    text-decoration: none !important;
  }

  .fancybox-container p.collapse:not(.show) {
    height: 37px;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .fancybox-container a.collapsed,
  .fancybox-container a[data-toggle='collapse'] {
    text-align: right !important;
    float: right;
    color: #0477aa;
    text-transform: none;
    font-weight: normal;
    font-family:
      Arial,
      Helvetica Neue,
      Helvetica,
      sans-serif;
    font-size: 12px;
    line-height: 15px;
    margin: 10px 0 0 0;
  }

  .fancybox-container .collapsing,
  .fancybox-container .collapse {
    min-height: 37px !important;
  }

  .fancybox-container .collapse.in {
    display: block !important;
    height: auto !important;
  }
}

@media (min-width: 600px) {
  .fancybox-inner.toggled {
    right: 255px;
  }

  .fancybox-inner.toggled .fancybox-toolbar {
    right: -255px !important;
  }

  .fancybox-container .decreaseWidth {
    right: 256px !important;
    left: 0px !important;
  }

  .fancybox-thumbs.decreaseWidth {
    width: auto !important;
  }

  .fancybox-container a.hiddenLink {
    display: none;
  }

  .fancybox-show-thumbs.toggled .fancybox-toolbar {
    right: 0 !important;
  }

  .fancybox-show-thumbs.toggled .fancybox-stage {
    right: 265px !important;
  }

  .fancybox-show-caption .fancybox-caption {
    margin: 0;
    height: auto !important;
    overflow: auto;
    bottom: 0 !important;
  }

  .fancybox-caption {
    background: #f1ecec !important;
    bottom: 0 !important;
    color: #6c6f73 !important;
    left: auto !important;
    right: -256px !important;
    top: 0 !important;
    width: 256px !important;
  }

  .fancybox-toolbar {
    left: auto !important;
    right: 0 !important;
    top: 0 !important;
    opacity: 1 !important;

    visibility: visible !important;
    height: auto !important;
  }

  .fancybox-caption:before {
    background-image: none !important;
  }

  .fancybox-thumbs.fancybox-thumbs-y {
    top: auto;
    width: auto;
    left: 0;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 0 !important;
  }

  .fancybox-show-thumbs .fancybox-stage {
    bottom: 85px !important;
  }

  .fancybox-show-caption .fancybox-toolbar {
    left: 0;
  }

  .fancybox-caption {
    right: 0px !important;
    bottom: 0px !important;
    height: 100% !important;
    top: 44px !important;
  }

  .fancybox-caption-wrap.toggled {
    right: -256px !important;
    display: none;
    width: 0;
    visibility: hidden;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 0px !important;
  }

  .fancybox-thumbs {
    width: 100% !important;
    top: auto !important;
  }

  .fancybox-container .collapse:not(.show) {
    display: block;
  }

  .fancybox-caption p,
  .fancybox-caption {
    color: #ffffff !important;
  }
}

.fancybox-caption {
  padding: 30px 20px !important;
}

.fancybox-thumbs {
  visibility: hidden !important;
  opacity: 0 !important;
}

.fancybox-bg {
  opacity: 1 !important;
}

.fancybox-show-thumbs .fancybox-thumbs {
  opacity: 1 !important;
  visibility: visible !important;
}

.fancybox-button--cp {
  width: 110px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 10px;
}

.fancybox-button--cp.toggled .show-cap,
.fancybox-button--cp .close-cap {
  display: none;
}

.fancybox-button--cp.toggled .close-cap,
.fancybox-button--cp .show-cap {
  display: block;
}

.fancybox-button--cp span {
  white-space: nowrap;
}

.fancybox-show-caption .fancybox-caption-wrap {
  display: none;
}

.fancybox-show-caption .fancybox-caption-wrap.toggled {
  display: block;
}

.fancybox-caption h4 {
  font-size: 15px;
  font-family: freight-sans-pro, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 0;
  margin-top: 0;
  text-align: left;
  text-transform: uppercase;
  color: #202020;
  background: none;
}

.fancybox-caption p,
.fancybox-caption {
  color: #9b9b9a !important;
  font-size: 12px !important;
  text-align: left !important;
}

.fancybox-button--arrow_right.fancybox-button[disabled],
.fancybox-button--arrow_left.fancybox-button[disabled] {
  display: none;
}

.fancybox-caption a[aria-expanded='true']:after {
  content: '- Read Less';
}

.fancybox-caption a[aria-expanded='false']:after {
  content: '+ Read More';
}

.fancybox-caption a.collapsed,
.fancybox-caption a[data-toggle='collapse'] {
  text-align: right !important;
  float: right;
  color: #0477aa;
  text-transform: none;
  font-weight: normal;
  font-family: freight-sans-pro, sans-serif;
  font-size: 0.75rem;
  line-height: 15px;
  margin: 10px 0 0 0;
  border-bottom: 1px dotted transparent;
  font-weight: 700;
  text-transform: uppercase;
}

.fancybox-caption a:hover {
  border-bottom: 1px dotted #0477aa;
}

.fancybox-thumbs {
  background: rgba(0, 0, 0, 1);
}

.fancybox-thumbs__list a:before {
  border: 2px solid #ffffff !important;
}

.fancybox-progress {
  background: #a7947a !important;
  height: 6px !important;
}

.fancybox-share.fancybox-content h1 {
  background-image: url(https://www.gia.edu/img/icons/heading-underline.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 0 0 15px 0;
  letter-spacing: -2px;
  margin-bottom: 15px;
  font-family: $din-web-condensed;
  font-weight: 500;
  color: #202020;
  text-transform: uppercase;
  font-size: 48px;
}

.fancybox-button.fancybox-focus {
  outline: 0 !important;
}

.fancybox-container:not(.fancybox-can-zoomIn) .fa-search-plus:before {
  content: '\f010' !important;
}

.fancybox-container.fancybox-can-zoomIn .fa-search-plus:before {
  content: '\f00e';
}

.fancybox-button--arrow_left {
  border: 0;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px 20px !important;
  background-position: 50%;
  border-radius: 2px;
  background-image: url(https://www.gia.edu/img/icons/icon-white-left-arrow.png);
  background-repeat: no-repeat;
  background-size: 10px 20px;
  opacity: 0.5 !important;
}

.fancybox-button--arrow_left:hover,
.fancybox-button--arrow_left:focus,
.fancybox-button--arrow_left:active {
  opacity: 0.9 !important;
}

.fancybox-button--arrow_right {
  border: 0;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 15px 20px !important;
  background-position: 50%;
  border-radius: 2px;
  background-image: url(https://www.gia.edu/img/icons/icon-white-right-arrow.png);
  background-repeat: no-repeat;
  background-size: 10px 20px;
  opacity: 0.5 !important;
}

.fancybox-button--arrow_right:hover,
.fancybox-button--arrow_right:active,
.fancybox-button--arrow_right:focus {
  opacity: 0.9 !important;
}

.fancybox-inner {
  overflow: visible;
}

.galleryModule button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  right: 0;
  left: 0;
  text-align: center;
  width: 130px;
  font-size: 13px;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 13px;
  font-weight: 700;
  font-family: freight-sans-pro, sans-serif;
}

@media (max-width: 576px) {
  .fancybox-caption p,
  .fancybox-caption {
    color: #ffffff !important;
  }
}

.fancybox-slide--iframe {
  padding: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fancybox-slide > .modal-container {
    width: 650px;
  }
}
