.callout-container {
  padding: 10px;
  background: $white;
  border: 5px solid bg-colors('medium-grey');

  .contact-callout & {
    margin: 20px 0 60px;
  }
  .about & {
    margin: 70px 0 30px;
  }
}
