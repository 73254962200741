.xtooltip-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  opacity: 0.65;
  z-index: 1040;
}

.xtooltip {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(10px);
  background-color: #fff;
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  z-index: 1070;
  /* https://getbootstrap.com/docs/4.6/layout/overview/#z-index */
  padding: 1rem;
  border: 1px solid #eee;

  .btn.btn-primary {
    background-color: #000 !important;
  }

  @media all and (max-width: 576px) {
    min-width: 225px;
    width: 200%;
  }
  .xtooltip-content {
    font-family: $font-family-base;
  }
}

/* tooltip-arrow */
.xtooltip:after {
  content: '';
  position: absolute;

  /* position tooltip correctly */
  bottom: 100%;
  left: 0;

  /* the arrow */
  transform: translateX(15px);
  border: 12px solid #fff;
  border-color: transparent transparent white transparent;
}

.xtooltip-track {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  bottom: 0;

  button {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    margin: 0 5px;
    border: none;

    &[aria-selected='true'] {
      background: rgba(102, 102, 102, 1);
    }

    &:focus {
      outline: 2px solid rgb(80, 70, 230);
    }
  }
}

@media screen and (min-width: 768px) {
  .xtooltip-track button {
    width: 14px;
    height: 14px;
    /* min-width: 14px; */
    /* min-height: 14px; */
  }
}

.xtooltip-close {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  &:focus {
    outline: 2px solid rgb(80, 70, 230);
  }
}

.xtooltip-close:after {
  display: block;
  content: '\00D7';
  font-size: 1.75rem;
  color: rgb(102, 102, 102);
}
