footer {
  background-color: bg-colors('dark-grey');
  margin: 0;
  padding: 60px 0 50px;
  color: $white;

  a {
    color: $white;
    letter-spacing: 0;
    line-height: 1em;

    &:hover {
      border-bottom: 1px dotted $white;
      color: $white;
    }

    // @media only screen and (min-device-width: 586px) and (max-device-width: 769px){
    //     top: -50%;
    //     position: relative;
    // }
  }

  img {
    margin: 0;
  }

  @include media-breakpoint-only(xs) {
    padding: 0 0 10px;
  }

  .footer-links a[target='_blank']:after {
    content: '';
    display: inline-block;
    background-image: url('https://www.gia.edu/img/global-header/desktop/external-link-icon.svg');
    background-size: 100% 100%;
    height: 12px;
    width: 12px;
    margin-left: 0.5em;
    vertical-align: middle;
  }

  .footer-content-top {
    @include make-container(1008px, transparent);

    li {
      list-style: none;

      a {
        @include fontSize(12px);
        line-height: 2.4em;
      }
    }

    .brand {
      a {
        &:hover {
          border: none;
        }
      }

      img {
        margin-top: 7px;

        @include media-breakpoint-only(xs) {
          margin-bottom: 40px;
        }
      }

      .logo {
        width: 130px;
      }
    }

    .offset-md-1 {
      margin-top: 12px;
    }

    // ---------------------------------------------------------------------------------------------//
    // --- (1) [GIA-8547] Add GIA App promo callout to footer of GIA.edu ~ Begins
    // ---------------------------------------------------------------------------------------------//
    #footer-section-end {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      #preferences-oo-feedback {
        justify-content: flex-start;
        padding-bottom: 12px;
        border-bottom: 1px dotted bg-colors('dark-gray');

        @media (max-width: 767px) {
          padding-top: 12px;
          border-top: 1px dotted bg-colors('dark-gray');
        }
      }

      #preferences,
      #feedback {
        flex: 50%;
      }

      #preferences {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        padding: 0 18px;

        span a {
          font-size: 12px;
          text-transform: capitalize;
        }
      }

      #feedback {
        align-items: flex-start;
        padding: 0 18px;

        button#oo-feedback {
          display: none;
          // background-color: transparent;
          // color: #fff;
          // font-family: freight-sans-pro;
          // font-size: 0.875rem;
          // font-weight: 700;
          // line-height: 1rem;
          // padding: 0;
          // margin-top: 3px;
          // border: none;
          // &:before {
          //   content: "";
          //   display: block;
          //   background: url("https://www.gia.edu/onlineopinionV5/oo_tab_icon_1.gif") no-repeat;
          //   background-size: contain;
          //   width: 20px;
          //   height: 20px;
          //   float: left;
          //   margin: 0 6px 0 0;
          // }
        }

        @media (min-width: 576px) {
          display: none !important;
        }
      }

      #app-promo {
        margin: 1.5rem 0;

        @media (min-width: 758px) {
          margin: 0.75rem 0;
        }

        a {
          text-transform: none;
          font-size: 12px;

          &:before {
            content: '';
            display: block;
            background: url('https://www.gia.edu/img/icons/data-transfer-download-gold-4x.png') no-repeat;
            background-size: contain;
            width: 12.5px;
            height: 12.5px;
            float: left;
            margin: 0 6px 0 0;
          }

          &:hover,
          &focus {
            border-bottom-color: transparent;
          }
        }
      }

      .social-links {
        padding: 12px 18px 0 18px;
        border-top: 1px dotted bg-colors('dark-gray');
        border-bottom: 1px dotted bg-colors('dark-gray');

        @media (max-width: 767px) {
          width: 100%;
          text-align: center;
        }

        span {
          color: $white;
          font-size: $small-font-size;
        }

        #follow {
          margin-top: 7px;
        }

        li {
          display: inline;
        }

        a {
          &:hover {
            border-bottom: none;
          }
        }

        img {
          margin: 7px;
        }

        .addthis_toolbox {
          ul {
            margin-bottom: 0.75rem;
          }
        }
      }

      #gemkids-link-footer {
        display: block;
        background-image: url('https://www.gia.edu/gemkids_assets/img/global/gemkids-logo-white.png');
        background-size: 62px;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;
        width: 62px;
        height: 20px;
        border-bottom: 0;
      }
    }

    // ---------------------------------------------------------------------------------------------//
    // --- [GIA-8547] Add GIA App promo callout to footer of GIA.edu ~ Ends
    // ---------------------------------------------------------------------------------------------//
    .col-md-4 {
      li {
        line-height: 0.75rem;
        padding-bottom: 15px;
      }

      a {
        line-height: 0.75rem;
      }
    }
  }

  .footer-content-bottom {
    border-top: 1px solid $grey;
    padding-top: 35px;
    margin-top: 65px;

    .copyright {
      @include fontSize(11px);
      margin-top: 10px;

      span {
        @include media-breakpoint-only(xs) {
          display: block;
          line-height: 1.5;
        }
      }
    }

    @include media-breakpoint-only(xs) {
      @include fontSize(10px);
      padding-top: 26px;
      margin-top: 21px;
    }
  }

  // ---------------------------------------------------------------------------------------------//
  // --- (1) TrustArc CCM ~ Begins
  // ---------------------------------------------------------------------------------------------//
  #consent_blackbar.custom_consent_blackbar {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2147483649;

    // (^) UserWay z-index (2147483648) + 1
    #truste-consent-track {
      background-color: rgba(0, 0, 0, 0.9);
      border-top: 1.5px solid #e1e1e1;
      border-right: none;
      border-bottom: none;
      border-left: none;
      padding: 25px 0;

      #truste-consent-content {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin: 0 25px;
      }

      #truste-consent-text {
        flex: 0.875;
        color: #e1e1e1;
        font-size: 0.8125rem;
        margin: 0;

        a {
          color: #38aee0;
        }
      }

      #truste-consent-buttons {
        float: none;
        /* override */
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 0 0 0;

        button {
          flex: 1;
          background-color: rgb(112, 112, 112);
          color: #fff;
          font-size: 0.8125rem;
          font-family: freight-sans-pro, sans-serif;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 1px;
          width: 60%;
          margin: 5px 0;
          border: none;
        }
      }

      @media screen and (min-width: 515px) {
        #truste-consent-buttons {
          flex-direction: row;
          justify-content: center;

          button {
            width: 155px;
            margin: 0 6px 0 0;

            &:last-of-type {
              margin: 0;
            }
          }
        }
      }

      @media screen and (min-width: 1024px) {
        #truste-consent-content {
          flex-direction: row;
          margin: 0 40px;

          #truste-consent-buttons {
            align-items: center;
            margin: 0;
          }
        }
      }

      @media screen and (min-width: 1995px) {
        #truste-consent-content {
          justify-content: center;

          #truste-consent-text {
            flex: none;
            margin-right: 60px;
          }
        }
      }
    }
  }

  // ---------------------------------------------------------------------------------------------//
  // --- (1) TrustArc CCM ~ Ends
  // ---------------------------------------------------------------------------------------------//
  .language-select {
    @include fontSize(12px);
    width: 275px;
    background: #424242;
    border-radius: 4px;
    margin: 0 auto 20px;
    line-height: 1;
    cursor: pointer;
    padding: 12px 10px 10px 0px;

    @include media-breakpoint-only(xs) {
      margin-bottom: 26px;
    }

    a {
      color: text-colors('medium-grey');
      padding: 10px 20px;
      line-height: 32px;

      &:hover {
        font-weight: bold;
        text-decoration: none;
        color: text-colors('medium-grey');
        border: none;
      }

      &.active {
        font-weight: 700;
      }
    }

    button.dropdown-toggle {
      background: transparent;
      border: 0;
      color: inherit;
    }
  }

  .locale-disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
  }
}

/* CLOSING TAG FOR 'footer' */

// (2) TrustArc CCM Modal
.truste-close-button-container {
  a[role='button'].truste-close-button {
    border-bottom: none;

    &:hover,
    &:focus {
      border-bottom: none;
    }
  }
}

.return-to-top {
  background: bg-colors('gold');
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  transition: all 0.4s ease-in-out 0s;

  a {
    @include fontSize(13px);
    letter-spacing: 1px;

    &:hover {
      border-bottom: none;
    }
  }

  img {
    content: url('https://www.gia.edu/assets/img/icons/down-toggle.svg');
    position: relative;
    right: 5px;
    transform: rotate(180deg);
  }

  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.get-updates {
  margin: 0 0 14px;
  padding-bottom: 14px;
  border-bottom: 1px dotted bg-colors('dark-gray');

  a {
    font-family: $font-family-sans-serif;
    text-transform: capitalize;
    margin-left: 5px;
  }

  @include media-breakpoint-only(xs) {
    margin-top: 24px;
  }
}

.no-opacity .language-toggle-dropdown .dropdown,
.no-pointerevents .language-toggle-dropdown .dropdown {
  display: none;
  opacity: 1;
  pointer-events: auto;
}

.no-opacity .language-toggle-dropdown.active .dropdown,
.no-pointerevents .language-toggle-dropdown.active .dropdown {
  display: block;
}

.language-toggle-dropdown:focus .dropdown {
  opacity: 1;
  pointer-events: auto;
}

.language-toggle-dropdown:focus:after {
  border-color: $white transparent;
  border-width: 6px 6px 0 6px;
  margin-top: -3px;
}

.terms {
  @include make-container(1008px, transparent);
  text-align: center;
  margin: 0 auto;

  ul {
    margin-bottom: 0;
  }

  li {
    display: inline;

    &:after {
      content: ' |';
    }

    &:last-of-type {
      &:after {
        content: '';
      }
    }

    a {
      @include fontSize(12px);
    }
  }
}
