.simple-slider {
  position: relative;
  max-width: calc(100% - 32px);
  aspect-ratio: 1 / 1;
  margin: 1rem auto;

  @media all and (min-width: 576px) {
    max-width: 35%;
  }

  .slick-arrow {
    &.slick-prev {
      left: -35px;
      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 24px;
        color: black;

        background-image: url('https://www.gia.edu/img/icons/arrow-previous.png');
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: 100%;
        background-position: center;
      }
    }
    &.slick-next {
      right: -35px;
      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 24px;
        color: black;

        background-image: url('https://www.gia.edu/img/icons/arrow-next.png');
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: 100%;
        background-position: center;
      }
    }
  }

  /* || --- Slick Dots (overrides) --- */
  .slick-dots {
    // position: absolute;
    // bottom: -25px;
    // display: block;
    // width: 100%;
    // padding: 0;
    // margin: 0;
    // list-style: none;
    // text-align: center;
    li {
      // position: relative;
      // display: inline-block;
      // width: 20px;
      // height: 20px;
      margin: 0;
      // padding: 0;

      // cursor: pointer;

      button {
        // font-size: 0;
        // line-height: 0;

        // display: block;

        // width: 20px;
        // height: 20px;
        // padding: 5px;

        // cursor: pointer;

        // color: transparent;
        // border: 0;
        // outline: none;
        // background: transparent;
        // &:hover,
        // &:focus {
        //   outline: none;
        //   &:before {
        //     opacity: 1;
        //   }
        // }
        &:before {
          // font-family: 'slick';
          font-size: 12px;
          // line-height: 20px;
          // position: absolute;
          // top: 0;
          // left: 0;
          // width: 20px;
          // height: 20px;
          // content: '•';
          // text-align: center;
          // opacity: 0.25;
          color: $black;
          // -webkit-font-smoothing: antialiased;
          // -moz-osx-font-smoothing: grayscale;
        }
      }
    }
    li.slick-active {
      button:before {
        opacity: 0.75;
        color: $black;
      }
    }
  }
}
