hr {
  border: 0; //single dotted border, all CSS
  &.dotted {
    border-top: 1px dotted rgba(bg-colors('medium-grey'), 0.7);
    height: 1px;
    width: 100%;
  }
  &.dotted-double {
    /* border-top: 1px dotted rgba(bg-colors("medium-grey"), .95);
        border-bottom: 1px dotted rgba(bg-colors("medium-grey"), .95); */
    background-image: linear-gradient(to right, bg-colors('medium-grey') 50%, rgba($black, 0) 0%),
      linear-gradient(to right, bg-colors('medium-grey') 50%, rgba($black, 0) 0%);
    background-size: 3px 1px;
    background-repeat: repeat-x;
    background-position: bottom, top;
    height: 3px;
    .container {
      @include make-container(1008px);
    }
  }
  &.solid-single {
    border-top: 1px solid rgba(bg-colors('medium-grey'), 0.7);
    margin: 0 0 5px 0;
    width: 100%;
  }
  &.small {
    height: 2px;
    width: 36px;
    background-color: bg-colors('gold');
  }
  .sidebar & {
    &:last-of-type {
      display: none;
    }
  }
  header & {
    clear: both;
  }
}

//Special left and right double dotted borders, not HR
.border-right-dotted {
  background-image: url('https://www.gia.edu/img/icons/bg-dots-double-vertical.png');
  background-repeat: repeat-y;
  background-position: right;
  @include media-breakpoint-down(md) {
    background: none;
  }
}

.border-left-dotted {
  background-image: url('https://www.gia.edu/img/icons/bg-dots-double-vertical.png');
  background-repeat: repeat-y;
  background-position: left;
  @include media-breakpoint-down(md) {
    background: none;
  }
}
.border-right-single-dotted {
  background-image: url('https://www.gia.edu/img/icons/bg-vert-dots.png');
  background-repeat: repeat-y;
  background-position: right;
  @include media-breakpoint-down(md) {
    background: none;
  }
}

.border-left-single-dotted {
  background-image: url('https://www.gia.edu/img/icons/bg-vert-dots.png');
  background-repeat: repeat-y;
  background-position: left;
  @include media-breakpoint-down(md) {
    background: none;
  }
}
