.intro {
  @include make-container(1008px, 'transparent');
  padding-top: 30px;

  h1 {
    @include header-underline(center);
    margin-bottom: 25px;
    padding-top: 5px;
  }

  .preheader {
    display: block;
    color: text-colors('gold');
    font-size: $font-size-sm;
    font-family: $callout-header-font;
    letter-spacing: 1px;
    font-weight: $display5-weight;
    line-height: 1em;
    text-transform: uppercase;
  }

  .lead {
    text-align: center;
  }

  a {
    margin-top: 15px;
  }
}
