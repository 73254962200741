/* || Utility */
.letter-spacing-tighter {
  letter-spacing: -0.05em;
}

.letter-spacing-tight {
  letter-spacing: -0.025em;
}

.letter-spacing-normal {
  letter-spacing: 0em;
}

.letter-spacing-wide {
  letter-spacing: 0.025em;
}

.letter-spacing-wider {
  letter-spacing: 0.05em;
}

.letter-spacing-widest {
  letter-spacing: 0.1em;
}

.bg-none {
  background: none !important;
}

.bg-bottom {
  background-position: bottom !important;
}

.text-left.bg-bottom {
  background-position: left bottom !important;
}

.text-right.bg-bottom {
  background-position: right bottom !important;
}

.text-center.bg-bottom {
  background-position: center bottom !important;
}

.text-red {
  color: var(--red);
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-90 {
  opacity: 0.9;
}

/* || Bootstrap Overrides */
.bg-light {
  background-color: bg-colors('grey') !important;
}

/* || GTM  */
img[src$='$NAME'] {
  display: none;
}
