//Main dropdown nav
header {
  // position: relative;
  z-index: 1000;

  @include media-breakpoint-down(sm) {
    z-index: auto;
  }

  #detail-template &,
  #ggarticle-detail-template &,
  #article-detail-template &,
  #gct-detail-template & {
    z-index: initial;
  }

  /**
  * New Search developed alongside upgrade to Bootstrap 4.6.2 to GIA
  */
  #search-form {
    position: absolute;
    left: 340px;
    right: 0;

    &[data-site='HK'] {
      left: 66.67%;
    }
  }

  .form-group.form-group-search {
    position: absolute;
    right: 35px;
    width: 0;
    margin-bottom: unset;
    transition: width 100ms ease-out;
  }

  .search-toggle-btn {
    cursor: pointer;
    border: 0;
    background-image: url('https://www.gia.edu/img/icons/search.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 2rem;
    height: 2rem;
    transform: scale(1.25);
  }

  .search-toggle-btn.search-close {
    background-image: url('https://www.gia.edu/img/icons/x-lg.svg');
  }

  // Tweaks for Mobile
  #search-form-mobile {
    position: absolute;
    left: 15px;
    right: 0;

    &:after {
      content: '|';
      transform: translateX(5px);
      font-weight: 700;
      font-size: 1.2rem;
      color: #9b9b9a;
    }

    .form-group-search {
      position: absolute;
      right: 45px;
      width: calc(100% - 45px);
    }
  }
}

//top bar naviation and header
.top-bar,
.global-navbar {
  display: none;

  + hr.solid-single {
    display: none;
  }

  @media all and (min-width: 1149px) {
    display: block;

    + hr.solid-single {
      display: block;
    }
  }
}

//This section also includes the search bar for both mobile and desktop
.top-bar a:hover {
  border-bottom: none;
}

.top-bar .navbar-brand {
  border-bottom: none;
  width: 25%;
}

.top-bar {
  @include make-container(1008px);
  padding: 0;

  .logo {
    margin: 0px;
  }

  .navbar-nav li {
    padding: 0 0 0 25px !important;

    @include media-breakpoint-down(md) {
      padding: 0 0 0 13px !important;
    }
  }

  .navbar-nav .gemkids-menu-link {
    display: block;
    border-bottom: 0;
    background-image: url('https://www.gia.edu/gemkids_assets/img/global/logo-gemkids@2x.png');
    background-size: 62px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    width: 62px;
    height: 20px;
    margin-top: 0;
    padding: 0 1rem;
    text-indent: -9999px;

    &:hover {
      border-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      background-size: 46.5px;
      width: 46.5px;
      height: 15px;
      margin-left: 3px;
    }
  }

  .nav-link {
    color: bg-colors('gold');
    text-transform: uppercase;
    @include fontSize(12px);
    font-weight: bold;
    padding: 0px !important;
    font-family: $freight-sans-pro;
    letter-spacing: 1px;

    @include media-breakpoint-down(md) {
      @include fontSize(10px);
      letter-spacing: 0.9px;
    }
  }

  ::-ms-clear {
    display: none;
  }

  .navbar-collapse {
    z-index: 1005;
  }

  &.mobile {
    top: 15px;
    position: absolute;
    right: 119px;
    width: auto;
    z-index: 1001;

    .active {
      min-width: 280px !important;
      top: -22px !important;
      background: white;
    }

    div {
      display: inline;
    }
  }

  .sr-only {
    clip-path: none;
    clip: auto;
    width: auto;
    height: auto;
  }
}

.primary-nav {
  padding: 1px 0 !important;
}

.gemkids-menu-link-mobile {
  background-image: url('https://www.gia.edu/gemkids_assets/img/global/gemkids-logo-white.png');
  background-size: 75px;
  background-repeat: no-repeat;
  background-position-x: 15px;
  background-position-y: center;
}

/**
* Begins takeover main-nav
*/
.global-navbar[data-lang='jp'] .gn-top-level .gn-dropdown-trigger,
.global-navbar[data-lang='jp'] .gn-top-level > a,
html[lang='ja'] .gn-top-level .gn-dropdown-trigger,
html[lang='ja'] .gn-top-level > a {
  font-size: 0.8rem;
  padding: 0 2rem 0 0;
}

@media screen and (max-width: 768px) {
  .global-navbar:not([data-lang='jp']) .gn-top-level .gn-dropdown-trigger,
  .global-navbar:not([data-lang='jp']) .gn-top-level > a,
  html:not([lang='ja']) .global-nav .gn-top-level .gn-dropdown-trigger,
  html:not([lang='ja']) .global-nav .gn-top-level > a {
    font-size: 0.9375rem;
  }

  .global-navbar[data-lang='jp'] .gn-top-level .gn-dropdown-trigger,
  .global-navbar[data-lang='jp'] .gn-top-level > a,
  html[lang='ja'] .global-nav .gn-top-level .gn-dropdown-trigger,
  html[lang='ja'] .global-nav .gn-top-level > a {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 768px) {
  .global-navbar:not([data-lang='jp']) .gn-top-level .gn-dropdown-trigger,
  html:not([lang='ja']) .global-nav .gn-top-level .gn-dropdown-trigger {
    font-size: 0.9375rem;
  }

  .global-navbar[data-lang='jp'] .gn-top-level .gn-dropdown-trigger,
  html[lang='ja'] .global-nav .gn-top-level .gn-dropdown-trigger {
    font-size: 0.75rem;
  }
}

.global-navbar {
  background-color: #fff;
}

.global-nav {
  width: 100%;
  max-width: 1008px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1000;
  position: relative;

  .gn-dropdown {
    li:first-child {
      margin-top: 8px;
    }

    li:last-child {
      margin-bottom: 16px;
    }
  }
}

.global-nav > ul {
  margin: 0 auto;
  list-style: none;
  width: 100%;
  z-index: 1020;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gn-top-level-list > li {
  margin: 0 0.5rem;
}

.global-nav .gn-top-level {
  position: relative;
  margin: 0;
}

#global-header .global-nav .gn-top-level .gn-dropdown-trigger,
#global-header .global-nav .gn-top-level > a {
  position: relative;
  overflow: auto;
  width: auto;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  height: auto;
  line-height: 2.5em;
  margin: 0;
  border: 0;
  padding: 0 2rem 0 1rem;
  color: rgba(0, 0, 0, 0.66);
  font-size: 1.3125rem;
  font-weight: 100;
  font-family: $din-web-condensed;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Align caret vertically */
  z-index: 1021;
  background-color: #fff;
  /* have to use important to override other important in mobile nav */
  white-space: nowrap;
}

/* Using separate element instead of ::after for IE */
.gn-dropdown-trigger > .gn-arrow-down {
  transition: all 0.2s;
  display: inline-block;
  width: auto;
  position: absolute;
  right: 1rem;
  transform: translate(0%, -50%) rotate(0deg);
  height: 5px;
  line-height: 0px;
  top: 50%;
}

.gn-dropdown-trigger.dl-active > .gn-arrow-down {
  transform: translate(0%, -50%) rotate(180deg);
}

.gn-top-level .gn-dropdown-trigger:focus,
.gn-top-level .gn-dropdown-trigger:hover {
  color: rgba(0, 0, 0, 0.87);
  color: #94703e;
  /* gold. contrast: 4.52 */
}

.gn-top-level .gn-dropdown-trigger::before {
  display: none;
}

.gn-top-level .dl-submenu,
.gn-top-level .dl-menu {
  background-color: #fff;
}

/* All menu items */
.gn-top-level .dl-menu li {
  font-size: 0.8125rem;
  color: rgba(0, 0, 0, 0.6);
}

/* Nav Links *******************************************/
.gn-top-level .dl-menu a {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8125rem;
  font-size: inherit;
  font-family: freight-sans-pro, sans-serif;
  font-weight: 700;
  line-height: 1.5em;
  padding: 0.66rem 1.25rem;
}

.gn-top-level .dl-menu a:focus,
.gn-top-level .dl-menu a:hover {
  color: rgba(0, 0, 0, 0.87);
}

.gn-top-level .gn-dropdown-item a {
  /* text needs to wrap, `pre-wrap` causes enormous white space around text. `normal` works as expected */
  white-space: normal;
  padding-right: 4em;
}

/* Dropdown Container *********************************/
.gn-top-level .gn-dropdown {
  width: auto;
  box-shadow: 0 0 7px 1px rgba(102, 102, 102, 0.2);
  z-index: 1010;
  min-width: 250px;
  max-width: 300px;
}

.gn-top-level .gn-dropdown > * {
  width: 100%;
}

/* Right arrow for items with sub-menus ****************/
.global-nav .gn-top-level li:not(.dl-back) > a:not(:only-child)::after {
  content: url('https://www.gia.edu/img/global-header/mobile/right-gold-arrow.svg');
  position: absolute;
  line-height: 1em;
  height: 1em;
  width: auto;
  top: 50%;
  right: 1rem;
  transform: translate(0%, -50%);
}

/* Submenu Back Buttons ***********************************/
.global-nav .dl-back {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.global-nav .dl-back::before {
  content: url('https://www.gia.edu/img/global-header/mobile/right-gold-arrow.svg');
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translate(0%, -50%) rotate(180deg);
  line-height: 1em;
  height: 1em;
  width: auto;
}

.global-nav .dl-menuwrapper li.dl-back > a {
  padding: 0 2rem;
  line-height: 4em;
  background-color: inherit;
}

/* Indent menu items that have a back button */
.global-nav .dl-submenu li > a {
  padding-left: 2rem;
}

/* Hide back arrow used by mobile menu */
.global-nav .dl-back::after {
  display: none;
}

/* New Tab Icon ***************************************/
.global-nav a[target='_blank']::after {
  content: url('https://www.gia.edu/img/global-header/desktop/external-link-icon.svg');
  margin-left: 0.75em;
}

/* Animation 2  **********************************************/
.gn-dropdown.dl-animate-out-2 {
  -webkit-animation: MenuAnimOut2 0.3s ease-in-out forwards;
  -moz-animation: MenuAnimOut2 0.3s ease-in-out forwards;
  animation: MenuAnimOut2 0.3s ease-in-out forwards;
}

.gn-dropdown.dl-animate-in-2 {
  -webkit-animation: MenuAnimIn2 0.3s ease-in-out forwards;
  -moz-animation: MenuAnimIn2 0.3s ease-in-out forwards;
  animation: MenuAnimIn2 0.3s ease-in-out forwards;
}

.gn-top-level > .dl-submenu.dl-animate-in-2 {
  -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out forwards;
  -moz-animation: SubMenuAnimIn2 0.3s ease-in-out forwards;
  animation: SubMenuAnimIn2 0.3s ease-in-out forwards;
}

.gn-top-level > .dl-submenu.dl-animate-out-2 {
  -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out forwards;
  -moz-animation: SubMenuAnimOut2 0.3s ease-in-out forwards;
  animation: SubMenuAnimOut2 0.3s ease-in-out forwards;
}

@-webkit-keyframes MenuAnimOut2 {
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
}

@-moz-keyframes MenuAnimOut2 {
  100% {
    -moz-transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut2 {
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-moz-keyframes MenuAnimIn2 {
  0% {
    -moz-transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -moz-transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn2 {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn2 {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
}

@-moz-keyframes SubMenuAnimOut2 {
  0% {
    -moz-transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -moz-transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut2 {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

// ENDs takeover main-nav
