.report-supplementals {
  .btn {
    display: inline-block;
    flex: 1 0 auto;

    &.btn-primary,
    &.btn-primary:focus,
    &.btn-primary:hover {
      background-color: $black !important;
      color: #fff !important;
      border: 3px solid $black !important;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
    @media all and (min-width: 576px) {
      &[data-icon]:before {
        display: inline-block;
        content: '';
        width: 1.5rem;
        height: 1.25rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      &[data-icon='share']:before {
        background-image: url(//gia.edu/img/icons/share-icon.svg);
      }

      &[data-icon='download']:before {
        background-image: url(//gia.edu/img/icons/cloud-download.svg);
      }
    }
  }
}
