.card {
  .card-title {
    @include fontSize(15px);
    @include header-underline(center);
    font-family: $callout-header-font;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: 0;
    color: text-colors('dark-grey');
  }
}

.card-link {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
    border-bottom: transparent;
  }
}
