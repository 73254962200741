.report-supplemental {
  [data-icon]:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-125%, -50%);
    display: block;
    background-repeat: no-repeat;
    background-size: 12px;
    width: 12px;
    height: 12px;
  }

  [data-icon='download']:before {
    background-image: url(//gia.edu/img/icons/download-blue.svg);
  }

  [data-icon='email']:before {
    background-image: url(//gia.edu/img/icons/email-blue.svg);
  }
}
